import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { FiPlus } from "react-icons/fi";
import { find, map } from "lodash";

import { BottomFormAction, Breadcrumbs, Button, TitleText } from "components";
import {
  CustomSelectForm,
  InputForm,
  SelectChartOfAccountForm,
} from "components/HookForm";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import {
  useAccountGroupDetail,
  useDirectorate,
  usePostAccountGroup,
} from "hooks/accountGroup";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useChartOfAccount } from "hooks/chartOfAccount";

const userOptions = [
  { value: "13ac8fd6-c3d8-4260-83d9-2436f9869bc6", label: "User 1" },
  { value: "13ac8fd6-c3d8-4260-83d9-2436f9869bc6", label: "User 2" },
  { value: "13ac8fd6-c3d8-4260-83d9-2436f9869bc6", label: "User 3" },
  { value: "13ac8fd6-c3d8-4260-83d9-2436f9869bc6", label: "User 4" },
];

const accountCodeOptions = [
  {
    value: "13bc8fd6-c3d8-4260-83d9-2436f9869bc6",
    label: "4714.FAM.001",
    level: "1",
  },
  {
    value: "13wc8fd6-c3d8-4260-83d9-2436f9869bc6",
    label: "4714.FAM.002",
    level: "2",
  },
  {
    value: "13hc8fd6-c3d8-4260-83d9-2436f9869bc6",
    label: "4714.FAM.003",
    level: "3",
  },
  {
    value: "13kc8fd6-c3d8-4260-83d9-2436f9869bc6",
    label: "4714.FAM.004",
    level: "5",
  },
];

const FORM_TYPE = ["add", "edit"];
const LOCALE_FORM = {
  add: {
    title: "Tambah Group Akun",
  },
  edit: {
    title: "Set Kewenangan Akun",
  },
};

const AccountGroupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const postAccountGroup = usePostAccountGroup();

  const id = location?.state?.id ?? null;

  const { data: detail } = useAccountGroupDetail(["ag_detail", id]);
  const { data: coa_options, isLoading, refetch } = useChartOfAccount(["coa"]);
  const { data: directorate_options } = useDirectorate(["directorate"]);

  const [coaOptions, setCoaOptions] = useState([]);
  const [directorateOptions, setDirectorateOptions] = useState([]);

  const formType = useMemo(
    () => find(FORM_TYPE, (type) => location.pathname.includes(type)),
    []
  );

  const links = [
    {
      path: "/authority",
      label: "Daftar Kewenangan Akun",
    },
    {
      label: LOCALE_FORM[formType]?.title,
    },
  ];

  const schema = yup.object().shape({
    // is_parent: yup.boolean().required("Posisi akun baru wajib diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      accounts: [{ account_code: null, description: "" }],
    },
  });

  const {
    fields: accountList,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: "accounts",
  });

  useEffect(() => {
    if (coa_options) {
      setCoaOptions(
        map(coa_options, (data) => ({
          value: data?.coa_id,
          label: data?.full_code,
          description: data?.coa_name,
        }))
      );
    }
  }, [coa_options]);

  useEffect(() => {
    if (directorate_options) {
      setDirectorateOptions(
        map(directorate_options, (data) => ({
          value: data?.directorates_id,
          label: data?.directorate_name,
        }))
      );
    }
  }, [directorate_options]);

  useEffect(() => {
    if (detail) {
      methods.reset({
        directorat: {
          value: location?.state?.directorate_id,
          label: location?.state?.directorate_name,
        },
        accounts:
          detail?.data?.length > 0
            ? map(detail?.data, (account) => ({
                account_code: account?.account_id
                  ? {
                      value: account?.account_id,
                      label: account?.full_code,
                      coa_name: account?.coa_name,
                      id: account?.id,
                      full_code: account?.full_code,
                    }
                  : null,
                description: account?.coa_name,
                account_id: account?.id,
              }))
            : [
                {
                  account_code: null,
                  description: "",
                },
              ],
      });
    }
  }, [detail]);

  const onCancel = () => {
    navigate(-1);
  };
  const onSubmit = (payload) => {
    if (payload) {
      const params = {
        accounts: map(payload?.accounts ?? [], (data) => {
          let result = {};
          result = {
            account_id: data?.account_code?.value,
            full_code: data?.account_code?.full_code,
            name: data?.account_code?.coa_name,
            directorate_id: id,
          };
          if (data?.account_id) result.id = data?.account_id;
          return result;
        }),
      };
      postAccountGroup.mutate(params, {
        onSuccess: (data, variables) => {
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          queryClient.invalidateQueries("ar");
          navigate("/authority");
        },
        onError: (error, variables) => {
          //
        },
      });
    }
  };

  const renderAllModal = () => {
    return null;
  };

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className="h-2" />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">{LOCALE_FORM[formType]?.title}</TitleText>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-4 space-y-4">
          <FormProvider {...methods}>
            <CustomSelectForm
              name={`directorat`}
              options={directorateOptions}
              label="Direktorat"
              isDisabled
            />
            <div className="space-y-2">
              <div className="text-lg font-semibold">
                Daftar Akun yang Berhak Digunakan
              </div>
              {map(accountList, (account, key) => (
                <div
                  key={account.id}
                  className="flex items-end justify-center space-x-4"
                >
                  <AccountSection
                    options={coaOptions}
                    name={`accounts.${key}`}
                    data={account}
                  />
                  <Button
                    onClick={() =>
                      key === 0
                        ? append({ account_code: null, description: "" })
                        : remove(key)
                    }
                    className={
                      key === 0
                        ? "text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 h-[40px] min-h-[40px] w-[40px] p-0 flex-none"
                        : "text-white border-error-600 bg-error-600 hover:bg-error-700 hover:border-error-700 h-[40px] min-h-[40px] w-[40px] p-0 flex-none"
                    }
                  >
                    {key === 0? <FiPlus /> : <BiTrash />}
                  </Button>
                </div>
              ))}
            </div>
          </FormProvider>
        </div>
      </div>
      <div className="h-24" />
      <BottomFormAction
        onCancel={onCancel}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </>
  );
};

const AccountSection = ({ options, name, data }) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const account_code = watch(`${name}.account_code`);
    if (account_code) {
      setValue(`${name}.description`, account_code?.coa_name);
    }
  }, [watch(`${name}.account_code`)]);

  return (
    <>
      <SelectChartOfAccountForm
        name={`${name}.account_code`}
        className="w-full"
        label={"Kode Akun"}
        level={8}
        menuPortalTarget={document.body}
      />
      <InputForm
        name={`${name}.description`}
        className={`py-2 px-4 border w-full rounded-md`}
        label={"Uraian"}
        disabled
        placeholder={"Uraian"}
        required={true}
      />
    </>
  );
};

export default AccountGroupForm;
