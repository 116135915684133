import { Button, Input } from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectCoaByLevelForm,
} from "components/HookForm";
import { useTaxesQuery } from "hooks/externalService";
import { map, sumBy } from "lodash";
import React, { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router";

const Vendor = ({ disabled }) => {
  const location = useLocation();

  const { control, watch, resetField } = useFormContext();
  const { fields: accounts, append: addAccount, remove: removeAccount} = useFieldArray({
    control,
    name: "accounts",
  });

  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  useEffect(() => {
    !isDetail && resetField("total_trx_value", {
      defaultValue: sumBy(watch("accounts"), (account) =>
        account.activity_details?.length > 0
          ? sumBy(
              account.activity_details,
              (activity_detail) =>
                parseFloat(activity_detail.value ? activity_detail.value : 0) +
                parseFloat(
                  activity_detail.taxes?.reduce(
                    (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                    0
                  )
                )
            )
          : 0
      ),
    });
  }, [
    sumBy(watch("accounts"), (account) =>
      sumBy(
        account.activity_details,
        (activity_detail) =>
          parseFloat(activity_detail.value) +
          parseFloat(
            activity_detail.taxes?.reduce((acc, o) => acc + o?.value, 0)
          )
      )
    ),
  ]);

  return (
    <div className="space-y-4">
      {map(accounts, (account, key) => (
        <div key={account.id} className="space-y-4">
          <div className="w-full flex gap-4">
            <div className="w-full">
              <CollapsingCard title={`Akun Belanja ${key + 1}`}>
                <SelectCoaByLevelForm
                  name={`accounts.${key}.shipping_account`}
                  className="w-full"
                  label="Akun Belanja"
                  placeholder="Pilih akun belanja"
                  level={9}
                  menuPortalTarget={document.body}
                  isDisabled={disabled}
                />
              </CollapsingCard>
            </div>
            {key > 0 && !disabled && (
              <Button
                className="bg-red-600 border-red-600"
                startIcon={<BiTrash size={18} />}
                iconOnly
                onClick={() => removeAccount(key)}
              />
            )}
          </div>
          <AccountDetail name={`accounts.${key}`} disabled={disabled} />
        </div>
      ))}
      <div className="flex items-center justify-end">
        {!disabled && (
          <Button
            startIcon={<FiPlus />}
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
            onClick={() =>
              addAccount({
                shipping_account: null,
                activity_details: [
                  {
                    activity_detail: null,
                    unit_cost: "",
                    qty: "",
                    volume: "",
                    taxes: [
                      {
                        tax: null,
                        billing_code: "",
                        value: "",
                      },
                    ],
                  },
                ],
              })
            }
          >
            Tambah Akun
          </Button>
        )}
      </div>
    </div>
  );
};

export default Vendor;

export const TaxForm = ({ name, disabled }) => {
  const methods = useFormContext();
  const {
    fields: taxesField,
    remove: removeTax,
    append: appendTax,
    replace: replaceTax,
  } = useFieldArray({
    control: methods.control,
    name: `${name}.taxes`,
  });

  useEffect(() => {
    if (taxesField?.length === 0) {
      replaceTax([
        {
          tax: null,
          billing_code: "",
          value: 0,
        },
      ]);
    }
  }, []);

  const { data: taxes } = useTaxesQuery(["dt-realization-taxes"]);

  const getTaxAmount = (name) =>
    taxes?.find((tax) => tax?.nama === name)?.value / 100 || 0;

  return (
    <>
      <span className="span text-primary-600 font-bold text-lg">Pajak</span>
      {taxesField?.map((field, index) => (
        <div className="flex flex-row items-end gap-4" key={field.id}>
          <CustomSelectForm
            name={`${name}.taxes.${index}.tax`}
            options={taxes?.map((tax) => ({
              label: tax?.nama,
              value: tax?.id,
            }))}
            label="Pajak"
            placeholder="Pajak"
            isDisabled={disabled}
          />
          <InputForm
            name={`${name}.taxes.${index}.billing_code`}
            label="Kode Billing"
            placeholder="Masukkan kode billing"
            className="w-full"
            disabled={disabled}
          />
          <NumberInputForm
            name={`${name}.taxes.${index}.value`}
            label="Nilai Pajak (Rp)"
            className="w-full"
            placeholder="0"
            value={
              parseFloat(methods.watch(`${name}.value`)) *
                getTaxAmount(
                  methods.watch(`${name}.taxes.${index}.tax`)?.label
                ) || 0
            }
            disabled
          />
          {index > 0 && !disabled && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeTax(index)}
            />
          )}
        </div>
      ))}

      <div className="flex flex-1 flex-col gap-4 items-end border-y-[1px] border-gray-100 mt-4 py-4">
        {!disabled && (
          <Button
            onClick={() => {
              appendTax({
                tax_id: "",
                tax: null,
                billing_code: "",
                value: 0,
              });
            }}
            className="bg-white border-gray-600 text-gray-600 hover:text-white"
            startIcon={<BiPlus />}
          >
            Tambah Pajak
          </Button>
        )}
        <div className="space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Total Pajak (Rp)</span>
          </label>
          <NumericFormat
            value={methods
              .watch(`${name}.taxes`)
              ?.reduce(
                (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                0
              )}
            placeholder="0"
            className="w-full"
            customInput={Input}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            disabled
          />
        </div>
        <span className="text-sm inline-block font-semibold bg-primary-50 text-primary-600 rounded-xl px-3 py-1">
          Total Realisasi (Rp):{" "}
          <span className="font-bold">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(
              parseFloat(
                methods.watch(`${name}.value`)
                  ? methods.watch(`${name}.value`)
                  : 0
              ) +
                parseFloat(
                  methods
                    .watch(`${name}.taxes`)
                    ?.reduce(
                      (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                      0
                    )
                )
            )}
          </span>
        </span>
      </div>
    </>
  );
};

const AccountDetail = ({ name, disabled }) => {
  const { control } = useFormContext();
  const { fields: activityDetails, append: addActivityDetail, remove: removeActivityDetail } = useFieldArray({
    control,
    name: `${name}.activity_details`,
  });
  return (
    <div>
      {map(activityDetails, (activityDetail, key) => (
        <div className="flex w-full gap-4">
          <div className="w-full">
            <CollapsingCard key={activityDetail.id} title="Daftar Detail Kegiatan">
              <div className="space-y-4">
                <SelectCoaByLevelForm
                  name={`${name}.activity_details.${key}.activity_detail`}
                  className="w-full"
                  label="Detail Kegiatan"
                  placeholder="Pilih detail kegiatan"
                  level={11}
                  menuPortalTarget={document.body}
                  isDisabled={disabled}
                />
                <div className="mt-4 grid grid-cols-3 gap-4">
                  <InputForm
                    name={`${name}.activity_details.${key}.value`}
                    label={"Nilai (Rp)"}
                    className={`py-2 px-4 border w-full rounded-md`}
                    disabled={disabled}
                  />
                </div>
                <div className="h-4" />
                <TaxForm
                  name={`${name}.activity_details.${key}`}
                  disabled={disabled}
                />
              </div>
            </CollapsingCard>
          </div>
          {key > 0 && !disabled && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeActivityDetail(key)}
            />
          )}
        </div>
      ))}
      {!disabled && <div className="h-[1px] w-full bg-gray-400 my-4" />}
      {!disabled && (
        <div className="flex items-center justify-center">
          <Button
            onClick={() =>
              addActivityDetail({
                activity_detail: null,
                unit_cost: "",
                qty: "",
                volume: "",
                taxes: [
                  {
                    tax: null,
                    billing_code: "",
                    value: "",
                  },
                ],
              })
            }
            startIcon={<FiPlus />}
          >
            Tambah Detail Kegiatan
          </Button>
        </div>
      )}
    </div>
  );
};
