import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, startCase } from "lodash";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil, BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import * as yup from "yup";

import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import { ConfirmationModal, FormModal } from "components/Modal";
import {
  useCreateDeposit,
  useDeleteDeposit,
  useDepositQuery,
  useUpdateDeposit,
} from "hooks/generalSettings";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { convertToIDR } from "pages/DownPayment/util";
import Form from "./form";
import { useLocation, useOutletContext } from "react-router";

const DEFAULT_VALUE_FORM = {
  name: "",
  number: "",
  bank: undefined,
  branch_name: "",
  billet_number: "",
  sk_number: "",
  placement_date: "",
  due_date: "",
  nominal: 0,
};

const GeneralSettingsDeposit = () => {
  const createDeposit = useCreateDeposit();
  const updateDeposit = useUpdateDeposit();
  const deleteDeposit = useDeleteDeposit();

  const [keyword, setKeyword] = useState(undefined);
  const [newPage, setNewPage] = useState(1);
  const [newLimit, setNewLimit] = useState(10);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupForm, setOpenPopupForm] = useState(false);

  const [selectedData, setSelectedData] = useState(null);

  const location = useLocation();

  const pathname = location?.pathname?.replace("/", "");

  const { userAccessRights } = useOutletContext();

  const roleAccess = userAccessRights['general-settings-deposit'];

  const links = [
    {
      label: "General Settings",
    },
    {
      label: "Deposito",
    },
  ];

  const {
    data: list,
    isLoading,
    refetch,
  } = useDepositQuery([
    "deposit",
    {
      page: newPage,
      size: newLimit,
      search: keyword,
    },
  ]);

  const schema = yup.object().shape({
    name: yup.string().required("Wajib diisi"),
    number: yup.string().required("Wajib diisi"),
    branch_name: yup.string().required("Wajib diisi"),
    permit_letter: yup.string().required("Wajib diisi"),
    interest: yup.number().required("Wajib diisi").min(1, "Wajib diisi"),
    sk_number: yup.string().required("Wajib diisi"),
    nominal: yup.number().required("Wajib diisi").min(1, "Wajib diisi"),
    bank: yup
      .object({
        label: yup.string().required("Wajib diisi"),
        value: yup.string().required("Wajib diisi"),
      })
      .typeError("Wajib diisi"),

    placement_date: yup.date().typeError("Wajib diisi").required(),
    due_date: yup.date().typeError("Wajib diisi").required(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUE_FORM,
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onTriggerAdd = () => {
    methods.reset(DEFAULT_VALUE_FORM);
    setOpenPopupForm(true);
  };

  const onTriggerEdit = (data) => {
    methods.reset({
      ...data,
      bank: {
        label: data?.bank_name,
        value: data?.bank_id,
      },
      type: {
        label: startCase(data?.type),
        value: data?.type,
      },
      placement_date: moment(data?.placement_date).toDate(),
      due_date: moment(data?.due_date).toDate(),
      id: data?.id,
    });
    setOpenPopupForm(true);
  };

  const onTriggerDelete = (data) => {
    setSelectedData(data);
    setOpenPopupDelete(true);
  };

  const onHandleDelete = () => {
    deleteDeposit.mutate(
      { id: selectedData?.id },
      {
        onSuccess: (data) => {
          setSelectedData(null);
          setOpenPopupDelete(false);
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          refetch();
        },
      }
    );
  };

  const onSubmit = (payload) => {
    const params = {
      ...payload,
      bank_name: payload?.bank?.label,
      bank_id: payload?.bank?.value,
      placement_date: moment(payload?.placement_date).format("YYYY-MM-DD"),
      due_date: moment(payload?.due_date).format("YYYY-MM-DD"),
    };
    if (payload?.id) {
      updateDeposit.mutate(
        { id: payload?.id, payload: params },
        {
          onSuccess: (data) => {
            methods.reset(DEFAULT_VALUE_FORM);
            setOpenPopupForm(false);
            enqueueSnackbar({
              message: data?.message ?? "Success",
              variant: "success",
            });
            setTimeout(() => {
              refetch();
            }, 100);
          },
        }
      );
    } else {
      createDeposit.mutate(params, {
        onSuccess: (data) => {
          methods.reset(DEFAULT_VALUE_FORM);
          setOpenPopupForm(false);
          enqueueSnackbar({
            message: data?.message ?? "Success",
            variant: "success",
          });
          setTimeout(() => {
            refetch();
          }, 100);
        },
      });
    }
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {index + 1}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "Nama",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "number",
      title: "Nomor Rekening",
      dataIndex: "number",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "bank_name",
      title: "Bank",
      dataIndex: "bank_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "branch_name",
      title: "Cabang",
      dataIndex: "branch_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "permit_letter",
      title: "Surat Izin",
      dataIndex: "permit_letter",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "interest",
      title: "Bunga",
      dataIndex: "interest",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "sk_number",
      title: "Nomor SK",
      dataIndex: "sk_number",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "placement_date",
      title: "Tanggal Placement",
      dataIndex: "placement_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "due_date",
      title: "Jatuh Tempo",
      dataIndex: "due_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "nominal",
      title: "Nominal",
      dataIndex: "nominal",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {convertToIDR(value)}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center justify-center w-full">
          {roleAccess?.canUpdate && (
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerEdit(data)}
            />
          )}
          {roleAccess?.canDelete && (
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onTriggerDelete(data)}
            />
          )}
        </div>
      ),
    },
  ];

  const renderAllModal = () => (
    <>
      <ConfirmationModal
        open={openPopupDelete}
        onClose={() => setOpenPopupDelete(false)}
        onSubmit={onHandleDelete}
        text={"Apakah Anda yakin akan menghapus data ini?"}
      />
      <FormModal
        title={methods.watch("id") ? "Edit Deposito" : "Tambah Deposito"}
        open={openPopupForm}
        onClose={() => setOpenPopupForm(false)}
        onSubmit={methods.handleSubmit(onSubmit)}
        bodyClassname="xl:max-h-[80vh] h-[80vh]"
      >
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </FormModal>
    </>
  );

  return (
    <>
      {renderAllModal()}
      <Breadcrumbs items={links} />
      <div className={`md:flex block items-center my-3`}>
        <TitleText className="flex-1">Daftar Deposito</TitleText>
        <div className="flex space-x-4 justify-end flex-1">
          {roleAccess?.canCreate && (
            <Button
              startIcon={<FiPlus />}
              onClick={onTriggerAdd}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            >
              Tambah
            </Button>
          )}
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4 w-[81vw]">
            <div className="space-x-5 flex">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
            <Table
              bordered
              stripped
              layout="auto"
              className="w-full"
              columns={tableColumns}
              dataSource={list?.result}
              isLoading={isLoading}
              onChangePage={setNewPage}
              onChangeRowsPerPage={setNewLimit}
              pagination={{
                page: list?.paginator?.currentPage,
                limit: list?.paginator?.limit,
                total: list?.paginator?.itemCount,
                previous_pages: list?.paginator?.page > 1,
                next_pages: !(
                  list?.paginator?.page === list?.paginator?.pageCount
                ),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettingsDeposit;
